<template>
  <component :is="commonComponent" id="terms" title="Terms of services">
    <section id="terms-content" class="pc_padding">
      <div class="common-content-row">
        Welcome to {{ domainConfig["IAmURL"] }}! Please carefully read these Terms and
        Conditions ("Terms") before using our website ("the Site"). By accessing
        and using the Site, you agree to be bound by these Terms. If you do not agree with any part
        of these Terms, please refrain from using the Site.
      </div>

      <div class="common-content-head">
        1. Acceptance of Terms
      </div>

      <div class="common-content-row">
        By accessing and using lighteatsrecipes.online, you acknowledge and agree to comply with
        these Terms. Continued use of the Site signifies your acceptance of these Terms.
      </div>

      <div class="common-content-head">
        2. Permitted Use
      </div>

      <div class="common-content-row">
        The Site is intended for personal, non-commercial use only. You agree not to use the Site
        for any unlawful or prohibited purpose, as well as to respect any usage restrictions
        outlined within these Terms.
      </div>

      <div class="common-content-head">
        3. Intellectual Property Rights
      </div>

      <div class="common-content-row">
        All content on lighteatsrecipes.online, including images, text, graphics, and logos, is the
        exclusive property of lighteatsrecipes.online or its content providers and is protected by
        copyright and other intellectual property laws. Unauthorized reproduction, distribution, or
        modification of content without written permission is prohibited.
      </div>

      <div class="common-content-head">
        4. User Conduct
      </div>

      <div class="common-content-row">
        You agree to use the Site in a manner that is lawful, respectful, and considerate of other
        users. You are prohibited from posting or transmitting any content that is offensive,
        defamatory, or infringes on others' rights.
      </div>

      <div class="common-content-head">
        5. Disclaimer of Warranties
      </div>

      <div class="common-content-row">
        The Site is provided "as is" and "as available."
        {{ domainConfig["IAmURL"] }} makes no warranties, either express or implied,
        regarding the operation of the Site or the accuracy, reliability, or completeness of the
        information provided.
      </div>

      <div class="common-content-head">
        6. Limitation of Liability
      </div>

      <div class="common-content-row">
        lighteatsrecipes.online shall not be liable for any damages resulting from your use or
        inability to use the Site, including but not limited to direct, indirect, incidental,
        punitive, and consequential damages.
      </div>

      <div class="common-content-head">
        7. Indemnification
      </div>

      <div class="common-content-row">
        You agree to indemnify and hold harmless {{ domainConfig["IAmURL"] }}, its
        affiliates, officers, directors, employees, and agents from any claims, liabilities,
        damages, and expenses (including attorney fees) arising from your use of the Site or breach
        of these Terms.
      </div>

      <div class="common-content-head">
        8. Modifications to Terms
      </div>

      <div class="common-content-row">
        lighteatsrecipes.online reserves the right to modify these Terms at any time, with any
        changes becoming effective immediately upon posting. Continued use of the Site after such
        changes will constitute your acceptance of the updated Terms.
      </div>

      <div class="common-content-head">
        9. Termination
      </div>

      <div class="common-content-row">
        {{ domainConfig["IAmURL"] }} may terminate or restrict your access to the Site at
        any time, with or without cause, and without prior notice.
      </div>

      <div class="common-content-head">
        10. Governing Law
      </div>

      <div class="common-content-row">
        These Terms are governed by and construed in accordance with the laws of the jurisdiction in
        which lighteatsrecipes.online operates, without regard to its conflict of law principles.
      </div>

      <div class="common-content-head">
        11. Contact Information
      </div>

      <div class="common-content-row">
        For any questions or concerns regarding these Terms, please reach out to us at
        {{ domainConfig['IAMEMAIL'] }}.
      </div>

      <div class="common-content-row">
        Thank you for visiting lighteatsrecipes.online!
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Terms of services | ${this.domainConfig["IAmURL"]}`,
      meta: [
        {
          name: 'description',
          content: `Read the User Terms and Conditions for ${this.domainConfig["IAmURL"]}. Understand the rules and guidelines for using our ${this.domainConfig['iamkeywords']} sharing platform.`,
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>